import React from 'react'
// import logo from './logo.svg';
import me from './images/mephoto.JPEG';
import twitter from './images/twitter.png';
import linkedin from './images/linkedin.png';
import instagram from './images/instagram.png';
import EmailSignupForm from './components/EmailSignupFormCC';
import './App.css';

function App() {
  const [bounce, setBounce] = React.useState(0)

  const mcSign = localStorage.getItem('mc-sign') || sessionStorage.getItem('mc-sign');
  const showEmailSignup = !mcSign || mcSign !== 'true';

  const socials = [
    {
      name: 'Twitter',
      url: 'https://twitter.com/soul_808',
      icon: 'fa fa-twitter',
      src: twitter,
    },
    {
      name: 'Instagram',
      url: 'https://instagram.com/soul_808',
      icon: '',
      src: instagram,
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/brandontylerward/',
      icon: '',
      src: linkedin,
    }
  ]

  const socialMediaButton = (name, url, icon, src) => {
    // #TODO use props
    return (
      <div>
        <a className={name} href={url}>
          <img src={src} className="Social-icon" alt={`img-${name}`}/>
          {/*<i className={icon}></i>*/}
          {/*<span>{name}</span>*/}
        </a>
      </div>
    )
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="Hover-container">
          <div className="Banner-image">
            {/*<img src={logo} className="App-logo" alt="logo" />*/}
            <img src={me} className="Me-banner" alt="me-banner"
                 onMouseDown={() => setBounce(1)}
                 onAnimationEnd={() => setBounce(0)}
                 bounce={bounce}
            />
          </div>

          <div className="About-me">
            <h1>Brandon Tyler Ward</h1>
            <h2>Engineer - Entrepreneur - Artist</h2>
            <p>Dallas, Tx</p>
            <p>🧘‍♂️</p>
          </div>

          <div className="Social-icons">
            {
              socials.map( (social) => {
                return socialMediaButton(social.name, social.url, social.icon, social.src)
              })
            }
          </div>
        </div>


        {/*<p>*/}
        {/*  Edit <code>src/App.js</code> and save to reload.*/}
        {/*</p>*/}
        {/*<a*/}
        {/*  className="App-link"*/}
        {/*  href="https://reactjs.org"*/}
        {/*  target="_blank"*/}
        {/*  rel="noopener noreferrer"*/}
        {/*>*/}
        {/*  Learn React*/}
        {/*</a>*/}
      </header>
      { showEmailSignup ? (
      <footer className="Footer">
        <EmailSignupForm
          action='https://forwardvines.us1.list-manage.com/subscribe/post?u=2971c875822cb83cc365f7bbd&amp;id=aca54c9938'

          //Adding multiple fields:
          fields={[
            {
              name: 'FNAME',
              placeholder: 'Name',
              type: 'text',
              required: true
            },
            {
              name: 'EMAIL',
              placeholder: 'Email',
              type: 'email',
              required: true
            }
          ]}
          // // Change predetermined language
          // messages = {
          //   {
          //     sending: "Sending...",
          //     success: "Thank you for subscribing!",
          //     error: "An unexpected internal error has occurred.",
          //     empty: "You must write an e-mail.",
          //     duplicate: "Too many subscribe attempts for this email address",
          //     button: "Subscribe!"
          //   }
          // }
          // // Add a personalized class
          className='Email-Signup-Form'
          buttonClassName='Email-Signup-Form-btn'
        />
      </footer>
      ) : null}
    </div>
  );
}

export default App;
