/**
 * npm install react-mailchimp-form
 * https://github.com/gndx/react-mailchimp-form#readme
 */

import React from "react"
import jsonp from "jsonp"
import PropTypes from 'prop-types';

class EmailSignupForm extends React.Component {
  state = {};
  subCount = 0;

  handleSubmit(evt) {
    evt.preventDefault();
    const { fields, action } = this.props;
    const values = fields.map(field => {
      return `${field.name}=${encodeURIComponent(this.state[field.name])}`;
    }).join("&");
    const path = `${action}&${values}`;
    const url = path.replace('/post?', '/post-json?');
    const regex = /^([\w_.\-+])+@([\w-]+\.)+([\w]{2,10})+$/;
    const email = this.state['EMAIL'];
    (!regex.test(email)) ? this.setState({ status: "empty" }) : this.sendData(url);
  };

  sendData(url) {
    this.setState({ status: "sending" });
    jsonp(url, { param: "c" }, (err, data) => {
      if (data.msg.includes("already subscribed")) {
        this.subCount++;
        if (this.subCount > 1) {
          sessionStorage.setItem('mc-sign', 'true');
          setTimeout(() => {
            window.location.reload();
          }, 3000)
        }
        this.setState({ status: 'duplicate' });
      } else if (err) {
        this.setState({ status: 'error' });
      } else if (data.result !== 'success') {
        this.setState({ status: 'error' });
      } else {
        this.setState({ status: 'success' });
        localStorage.setItem('mc-sign', 'true');
        setTimeout(() => {
          window.location.reload();
        }, 3000)
      };
    });
  }

  render() {
    const { fields, styles, className, buttonClassName } = this.props;
    const messages = {
      ...EmailSignupForm.defaultProps.messages,
      ...this.props.messages
    }
    const { status } = this.state;
    return (
      <form onSubmit={this.handleSubmit.bind(this)} className={className}>
        {fields.map(input =>
          <input
            {...input}
            key={input.name}
            onChange={({ target }) => this.setState({ [input.name]: target.value })}
            defaultValue={this.state[input.name]}
          />
        )}
        <button
          disabled={status === "sending" || status === "success"}
          type="submit"
          className={buttonClassName}
        >
          {messages.button}
        </button>
        <div className='msg-alert'>
          {status === "sending" && <p style={styles.sendingMsg}>{messages.sending}</p>}
          {status === "success" && <p style={styles.successMsg}>{messages.success}</p>}
          {status === "duplicate" && <p style={styles.duplicateMsg}>{messages.duplicate}</p>}
          {status === "empty" && <p style={styles.errorMsg}>{messages.empty}</p>}
          {status === "error" && <p style={styles.errorMsg}>{messages.error}</p>}
        </div>
      </form>
    );
  }
}

EmailSignupForm.defaultProps = {
  messages: {
    sending: "Sending...",
    success: "Thank you for subscribing!",
    error: "An unexpected internal error has occurred.",
    empty: "You must write an e-mail.",
    duplicate: "Too many subscribe attempts for this email address",
    button: "Stay Connected"
  },
  buttonClassName: "",
  styles: {
    sendingMsg: {
      color: "#000000"
    },
    successMsg: {
      color: "#000000"
    },
    duplicateMsg: {
      color: "#ff0000"
    },
    errorMsg: {
      color: "#ff0000"
    }
  }
};

EmailSignupForm.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string
};

export default EmailSignupForm;